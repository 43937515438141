@import '../../sass/variables';

.wrapper {
  position: relative;
  margin-bottom: 30px;
  overflow: hidden;
  background: $colorGray900;
  border-radius: 6px;

  &.maxHeight {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;

    .code {
      width: 100%;
    }
  }
}

.header {
  padding: 18px 30px;
  border-bottom: 1px solid $colorGray500;
}

.pre {
  padding: 30px !important;
  margin: 0;
  overflow: auto;
  // font-family: $fontFamilyMono;
  font-size: 0.875rem;
  font-weight: $weight-medium;
  hyphens: none;
  line-height: 24px;
  color: white;
  text-align: left;
  word-break: normal;
  word-wrap: normal;
  tab-size: 4;
  white-space: pre;
  word-spacing: normal;

  .code {
    position: relative;
    display: inline-block;
    width: max-content;
    min-height: 24px;
    padding: 0;
    margin: 0;
    // font-family: $fontFamilyMono;
    font-weight: $weight-medium;
  }

  /* THEME */
  :global {
    .comment,
    .block-comment,
    .prolog,
    .doctype,
    .cdata {
      color: rgba(255 255 255 / 50%);
    }

    .property,
    .tag,
    .boolean,
    .number,
    .function-name,
    .constant,
    .symbol,
    .deleted {
      color: #a87df5;
    }

    .selector,
    .attr-name,
    .char,
    .builtin,
    .inserted {
      color: #a4df2e;
    }

    .punctuation,
    .entity,
    .url,
    .variable {
      color: #fff;
    }

    .string {
      color: #e6db74;
    }

    .operator,
    .keyword {
      color: #f32670;
    }

    .function {
      color: #66d9ef;
    }

    .atrule,
    .attr-value,
    .class-name {
      color: #66d9ef;
    }

    .regex,
    .important {
      color: #f32670;
    }

    .important {
      font-weight: normal;
    }

    .bold {
      font-weight: bold;
    }

    .italic {
      font-style: italic;
    }

    .entity {
      cursor: help;
    }

    .namespace {
      opacity: 0.7;
    }

    .language-json {
      color: white;

      .property {
        color: #66d9ef;
      }

      .operator {
        color: white;
      }

      .string {
        color: #e6db74;
      }
    }

    .language-markdown {
      color: white;
    }
  }
}
