@import '../../../../sass/variables';

.headSection {
  padding-top: 120px;
}

.content {
  margin: -70px auto 50px;

  a {
    display: inline !important;
    line-height: initial;
    word-wrap: break-word;
    overflow-wrap: break-word;
  }

  section > p {
    max-width: 730px;
    margin: 0 auto 30px;
    line-height: 1.7;
  }

  ol,
  ul {
    max-width: 730px;
    margin-right: auto;
    margin-left: auto;
  }

  h2,
  h3,
  h4,
  h5 {
    max-width: 730px;
    margin-right: auto !important;
    margin-left: auto !important;
  }

  img {
    max-width: 100%;
    height: auto;
    margin: 0 0 20px;

    &.mainImage {
      margin: 0;
    }
  }

  pre {
    padding: 20px;
    overflow-x: auto;
    font-family: Monaco;
    font-size: 0.875rem;
    color: $colorWhite;
    background: $colorGray900;
    border-radius: 3px;
  }

  blockquote {
    padding-left: 20px;
    margin: 20px 0;
    font-style: italic;
    border-left: 5px solid $colorGray200;
  }

  h3 {
    margin: 0 0 30px;
    font-size: 1.5rem;

    em {
      font-style: normal;
    }
  }

  h5 {
    margin: 0 0 20px;
    font-size: 1rem;
    color: rgba($colorGray900, 0.5);
    text-transform: uppercase;
  }

  ul {
    padding: 0 0 0 40px;
    line-height: 1.5;

    li {
      margin-bottom: 10px;
      list-style-type: disc;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  ol {
    li {
      margin: 0 0 5px;
    }

    a {
      text-decoration: none;

      &:hover,
      &:focus {
        text-decoration: underline;
      }
    }
  }

  :global {
    .imageTitle {
      display: block;
      font-size: 0.875rem;
      text-align: center;
    }

    .inlineCode {
      padding: 0 5px;
      font-family: Monaco;
      font-size: 0.875rem;
      color: $colorWhite;
      background: $colorGray900;
      border-radius: 3px;
    }
  }
}

.examplesTitle {
  display: block;
  letter-spacing: 1px;
}

.footer {
  max-width: 730px;
  margin: 0 auto 70px;
}

.title {
  margin: 15px 0 30px;
  line-height: 1.5;
}

.subtitle {
  margin: 45px 0 20px;
  font-size: 1.5rem;

  &.firstSubtitle {
    margin: 30px 0 20px;
  }
}

.videoIFrame {
  width: 100%;
  min-height: 200px;
  margin: 15px 0 30px;
}

.headerContent {
  max-width: 840px;
  margin: 0 auto;
  text-align: center;
}

.headerInfo {
  font-size: 1rem;
  color: $colorGray900;
}

.readingTime {
  display: inline-block;
  margin-bottom: 10px;
}

.backButtonWrapper {
  margin-bottom: 40px;
}

.noteTitle {
  display: block;
  margin-bottom: 10px;
}

.media {
  position: relative;
  z-index: 1;
  display: block;
  max-width: 730px;
  margin: 0 auto 20px;
}

@include media-breakpoint-up(md) {
  .content {
    margin: -140px auto 60px;
    font-size: 1.125rem;

    img {
      max-width: 100%;
    }
  }

  .footer {
    margin-bottom: 150px;
  }

  .title {
    margin: 15px 0 50px;
  }

  .subtitle {
    margin: 75px 0 30px;
    font-size: 2.125rem;

    &.firstSubtitle {
      margin: 50px 0 30px;
    }
  }

  .backButtonWrapper {
    margin-bottom: 20px;
  }

  .videoIFrame {
    min-height: 400px;
  }

  .media {
    margin: 0 auto 30px;
  }
}
