@import '../../../../sass/variables';

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 25px 30px;
  margin-top: 30px;
  background: $colorGray100;
  border-radius: 4px;
}

.content {
  display: flex;
  gap: 20px;
  align-items: center;
}

.photoWrapper {
  width: 60px;
  height: 60px;
  overflow: hidden;
  border-radius: 50%;
}

.role {
  display: block;
  font-size: 0.875rem;
  color: $colorGray500;
}

.name {
  font-size: 1rem;
  font-weight: $weight-bold;
  color: $colorGray900;
}

@include media-breakpoint-up(sm) {
  .wrapper {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 45px;
  }

  .content {
    gap: 25px;
  }
}
