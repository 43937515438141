@import '../../../../sass/variables';

.header {
  background: $colorGray100;
}

.dot {
  color: $colorYellow200;
}

.searchForm {
  display: none;
}

.articlesWrapper {
  max-width: 700px;
  margin: 0 auto;
}
