@import '../../../../../sass/variables';

.wrapper {
  padding: 32px 0;
  border-top: 1px solid $colorGray300;
  border-bottom: 1px solid $colorGray300;

  .orderedList {
    padding-left: 20px;
    counter-reset: orderedList;

    li {
      position: relative;
      margin-bottom: 30px;
      counter-increment: orderedList;

      &::before {
        position: absolute;
        top: 0;
        left: -20px;
        color: $colorGray900;
        content: counter(orderedList) '.';
        opacity: 0.5;
      }
    }
  }
}

.primaryTextWrapper {
  position: relative;

  &::after {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 150%;
    pointer-events: none;
    content: '';
    background: linear-gradient(to top, $colorWhite, hsl(0deg 0% 100% / 0%));
  }

  &.isOpen {
    &::after {
      display: none;
    }
  }
}

.showMoreButton {
  display: flex;
  gap: 8px;
  align-items: center;
  padding: 0;
  font-size: 0.875rem;
  font-weight: $weight-medium;
  color: $colorBlue300;
  background: none;
  border: none;
  appearance: none;
}

.angleDownIcon {
  width: 10px;
  height: 10px;
  transform: translateY(1px);
}
