@import '../../sass/variables';

.wrapper {
  display: flex;
  padding: 13px 20px 17px;
  font-size: 1.125rem;
  line-height: 1.625;
  background: $colorYellow100;
  border-radius: 4px;
}

.iconWrapper {
  flex-shrink: 0;
  width: 16px;
  height: 16px;
  margin-right: 20px;
  color: $colorGray900;
  transform: translateY(7px);
}

.icon {
  display: block;
}
