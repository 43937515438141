@import '../../sass/variables';

.list {
  display: inline-flex;
  height: 30px;
  padding: 0;
  margin: 0;
  border-radius: 4px;

  &.blue {
    border: 1px solid $colorGray400;

    .button {
      color: $colorGray400;

      &.active {
        color: $colorBlue300;
        border-color: $colorBlue300;
      }
    }
  }

  &.white {
    border: 1px solid $colorGray400;

    .button {
      color: $colorGray400;

      &.active {
        color: $colorGray900;
        background: $colorWhite;
        border-color: $colorWhite;
      }
    }
  }
}

.button {
  height: 30px;
  padding: 3px 10px;
  margin: -1px;
  font-size: 0.75rem;
  font-weight: $weight-bold;
  line-height: 1;
  background: none;
  border: 1px solid transparent;
  border-radius: 4px;
  transition: all 100ms ease-in-out;
  appearance: none;

  &.active {
    position: relative;
    z-index: 1;
  }
}
