@import '../../../../sass/variables';

.title {
  display: block;
  margin-bottom: 30px;
  font-size: 0.875rem;
  font-weight: $weight-bold;
  color: $colorGray900;
  text-transform: uppercase;
}

.list {
  max-width: 560px;
  padding: 0;
  margin: 0;
  list-style: none;
}

.item {
  margin-bottom: 45px;

  > :last-child {
    margin-bottom: 0;
  }
}

.link {
  display: block;
  text-decoration: none;

  &:hover .label,
  &:focus .label {
    text-decoration: underline;
  }
}

.imgWrapper {
  position: relative;
  padding-top: 45.2%;
  margin-bottom: 10px;
  overflow: hidden;
  font-size: 0;
}

.img {
  position: absolute;
  top: 50%;
  left: 0;
  max-width: 100%;
  border-radius: 3px;
  transform: translateY(-50%);
}

.date {
  display: block;
  margin-bottom: 5px;
  font-size: 0.875rem;
  line-height: 1.9;
  color: $colorGray400;
}

.label {
  font-size: 1.125rem;
  color: $colorBlue300;
}

@include media-breakpoint-up(sm) {
  .title {
    margin-bottom: 45px;
  }

  .content {
    display: flex;
  }

  .imgWrapper {
    flex-basis: 176px;
    flex-shrink: 0;
    height: 101px;
    padding-top: 0;
    margin: 0 30px 0 0;
  }
}
