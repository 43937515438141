@import '../../../../sass/variables';

.post {
  display: block;
  margin: 0 auto;
  text-decoration: none;

  &:hover {
    .readArticleFakeButton {
      text-decoration: underline;
    }

    .linkArrowWrapper {
      transform: translate(4px, -1px);
    }
  }
}

.previewImageWrapper {
  flex-shrink: 0;
  width: 100%;
  height: auto;
  margin: 0 0 20px;
  overflow: hidden;
  pointer-events: none;
}

.previewImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.date {
  display: block;
  margin-bottom: 8px;
  font-size: 0.875rem;
  color: rgba($colorGray900, 0.5);
}

.title {
  display: block;
  margin: 0 0 8px;
  font-size: 1.25rem;
  line-height: 1.5;
  color: $colorGray900;
}

.tagsWrapper {
  display: none;
}

.authorWrapper {
  display: block;
  margin-bottom: 20px;
  color: rgba($colorGray900, 0.5);
}

.content {
  display: block;
  pointer-events: none;
}

.buttonWrapper {
  display: block;
}

.readArticleFakeButton {
  display: flex;
  align-items: center;
  font-weight: $weight-bold;
  line-height: 1;
}

.linkArrowWrapper {
  width: 15px;
  height: 15px;
  margin-left: 10px;
  transition: transform 100ms $transition-function;
  transform: translateY(-1px);
}

// THEMES
.featured {
  .title {
    margin-bottom: 16px;
  }

  .content {
    margin: 0;
  }

  .preview {
    margin-bottom: 20px;
  }

  .previewImageWrapper {
    width: 100%;
    height: 100%;
    max-height: 430px;
  }
}

@include media-breakpoint-up(md) {
  .post {
    max-width: 300px;
  }

  .previewImageWrapper {
    width: 300px;
    height: 158px;
    margin: 0 0 15px;
  }

  .authorWrapper {
    margin-bottom: 30px;
  }

  .featured {
    display: block;
    width: 100%;
    max-width: 1110px;

    .title {
      font-size: 2.125rem;
    }

    .preview {
      margin-bottom: 30px;
    }
  }
}

@include media-breakpoint-up(lg) {
  .featured {
    display: flex;
    align-items: center;

    .content {
      padding: 0 0 0 60px;
    }

    .previewImageWrapper {
      flex-grow: 1;
      width: 350px;
      height: auto;
      max-height: none;
      margin: 0;

      & > div {
        height: 100%;
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .featured {
    display: flex;

    .previewImageWrapper {
      width: 565px;
    }
  }
}
