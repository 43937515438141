@import '../../../../sass/variables';

.code {
  margin: 45px 0;
}

.header {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  align-items: center;
  justify-content: space-between;
}
